<template>
  <v-dialog width="unset" v-model="dialog" persistent>
    <v-card light width="500px" clas="justify-center" rounded raised>
      <v-card-title class="px-2">Process Payment</v-card-title>
      <iframe :src="src" width="100%" height="700px"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import createSHA512Hash from '../../utils/SHA512.js';
import { api, auth } from '../../sharedPlugin';

export default {
  props: {
    paymentAmount: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      dialog: false,
      src: '',
      receiptPageUrl: ''
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  watch: {
    async dialog(newVal) {
      newVal
        ? window.addEventListener('message', await this.receiveMessage, false)
        : window.removeEventListener('message', this.receiveMessage);
    }
  },
  methods: {
    async getPayrocKeys() {
      try {
        const response = await api.Payroc.getKeysForSaleByClientId(
          this.user.clinicId,
          'sale'
        );
        return response;
      } catch (error) {
        console.error('Error getting payroc keys', error.message);
      }
    },
    async initPayrocIframe(payrocKeys) {
      try {
        const { url, terminal, currency, secret, receiptPageUrl } = payrocKeys;
        const BASEURL = url;
        const TERMINALID = terminal;
        const ORDERID = `${Math.round(Math.random() * 9999)}-${
          this.user.patientId
        }`;
        const CURRENCY = currency;
        const AMOUNT =
          typeof this.paymentAmount === 'string'
            ? parseFloat(this.paymentAmount.replace('$', '').replace(',', ''))
            : this.paymentAmount;
        const DATETIME = moment().format('DD-M-YYYY:HH:mm:ss:SSS');
        const SECRET = secret;

        this.receiptPageUrl = receiptPageUrl;

        // TERMINALID:ORDERID:AMOUNT:DATETIME:RECEIPTPAGEURL:VALIDATIONURL:SECRET
        const HASH = await createSHA512Hash(
          `${TERMINALID}:${ORDERID}:${AMOUNT}:${DATETIME}:${this.receiptPageUrl}:${SECRET}`
        );
        this.src =
          `${BASEURL}` +
          `?TERMINALID=${TERMINALID}` +
          `&ORDERID=${ORDERID}` +
          `&CURRENCY=${CURRENCY}` +
          `&AMOUNT=${AMOUNT}` +
          `&DATETIME=${DATETIME}` +
          `&CLIENTID=${this.user.clinicId}` +
          `&RECEIPTPAGEURL=${this.receiptPageUrl}` +
          `&HASH=${HASH}` +
          // custom fields
          '&APP=CONNECT';
      } catch (error) {
        const msg = `Error on iframe init: ${error.message}`;
        console.error(msg);
        throw Error(msg);
      }
    },
    async submit() {
      try {
        this.dialog = true;
        const payrocKeys = await this.getPayrocKeys();
        await this.initPayrocIframe(payrocKeys);
      } catch (error) {
        const msg = `Error processing payment on payroc gateway, if error persist please contact support: ${error.message}`;
        console.error(msg);
      }
    },
    async receiveMessage(event) {
      try {
        const origin = new URL(this.receiptPageUrl).origin;
        if (event.origin !== origin) {
          throw Error('untrusted origin'); // Ignore messages from untrusted origins
        }

        const data = event.data;

        // Process the data received from the iframe
        let payrocRes;
        switch (data.action) {
          case 'success':
            payrocRes = data.details;
            await this.$emit('onReceiveMessage', { payrocRes });
            break;
          case 'failed':
            payrocRes = data.details;
            await this.$emit('onReceiveMessage', { payrocRes });
            break;
          case 'forbidden':
            // Handle no authorized source
            break;
          default:
            break;
        }
      } catch (error) {
        const msg = `Error processing payment on app, if error persist please contact support: ${error.message}`;
        console.error(msg);
      }
    },
    async close() {
      this.dialog = false;
      window.location.reload();
    }
  }
};
</script>

<style></style>
