import { createHash } from 'crypto';

const createSHA512Hash = async (data) => {
  const hash = await createHash('sha512');
  hash.update(data);
  const hashedData = hash.digest('hex');
  return hashedData;
};

export default createSHA512Hash;
